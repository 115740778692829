import Vue from "vue";

export default class FormErrors {
  constructor() {
    this.fields = {};
  }

  setErrors(invalidFields) {
    for (let field in invalidFields) {
      this.invalidField(field, invalidFields[field]);
    }
  }

  invalidField(field, errMsg) {
    Vue.set(this.fields, field, errMsg);
  }

  validField(field) {
    Vue.delete(this.fields, field);
  }

  resetFields() {
    this.fields = {}
  }

  isFieldValid(field) {
    if (!field) {
      return "FieldsValidator.isFieldValid: no field";
    }
    if (!(field in this.fields) || this.fields[field].length > 0) {
      return false;
    }
    return true;
  }

  hasError(field, params) {
    if (!field) {
      return "FieldsValidator.hasError: не указан параметр field";
    }
    if (!(field in this.fields) || !this.fields[field].length) {
      return false;
    }
    if(params && params.nested && params.index > -1) {
      let {index, nested} = params
      if(this.fields[field][index] && this.fields[field][index][nested]) return true
      return false
    }
    return true;
  }

  getError(field, params) {
    if (!field) {
      return "FieldsValidator.hasError: не указан параметр field";
    }
    if (!(field in this.fields) || !this.fields[field].length) {
      return '';
    }
    if(params && params.nested && params.index > -1) {
      let {index, nested} = params
      if(this.fields[field][index] && this.fields[field][index][nested]) return this.fields[field][index][nested]
      return ''
    }
  }

  isValid() {
    if (!this.fields) {
      console.error("FieldsValidator.isValid(fields): fields is not set");
      return false;
    }

    for (let field in this.fields) {
      if (this.fields[field].length > 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * ValidateStrLenRange проверяет чтобы размер строки был не менее minLen символов
   * @param val {string} - проверяемое значение
   * @param minLen {number} - мимальное значение при котором поле будет валидным
   * @param maxLen {number} - максимальное значение при котором поле будет валидным
   * @param errMsg {string} - Сообщение об ошибке
   * @param field {string} - поле для проверки валидности
   * @return {boolean} - возвращает true если валидно, false если нет
   */
  validateStrLenRange (val, minLen, maxLen, errMsg, field) {
    if (!this.isFieldValid(field)) return false
    if (val.length < minLen || val.length > maxLen) {
      this.invalidField( field, errMsg)
      return false
    }
    this.validField( field)
    return true
  }

  /**
   * ValidateStrMinLen проверяет чтобы размер строки был не менее minLen символов
   * @param val {string} - проверяемое значение
   * @param minLen {number} - минимальное значение при котором поле будет валидным
   * @param errMsg {string} - сообщение об ошибке
   * @param field {string} - поле для проверки валидности
   * @return {boolean} - возвращает true если валидно, false если нет
   */
  validateStrMinLen (val, minLen, errMsg, field) {
    if (!this.isFieldValid(field)) return false

    if (val.length < minLen) {
      this.invalidField( field, errMsg)
      return false
    }
    this.validField(field)
    return true
  }

  /**
   * ValidateStrMaxLen проверяет чтобы размер строки был не менее minLen символов
   * @param val {string} - проверяемое значение
   * @param maxLen {number} - максимальное значение при котором поле будет валидным
   * @param errMsg {string} - Сообщение об ошибке
   * @param field {string} - поле для проверки валидности
   * @return {boolean} - возвращает true если валидно, false если нет
   */
  validateStrMaxLen (val, maxLen, errMsg, field) {
    if (!this.isFieldValid( field)) return false

    if (val.length > maxLen) {
      this.invalidField( field, errMsg)
      return false
    }
    this.validField( field)
    return true
  }
}
