import { DATE_DEFAULT_REGXP } from "@/util/const"

export default class ReservationRequestValidate {
  constructor(
    data,
  ) {
    this.data = data
  }

  validate() {
    let errors = {}
    this.validateCourse(errors)
    this.validateDate(errors)
    this.validateRequired('timeFrom', this.data.timeFrom, errors)
    this.validateRequired('timeTo', this.data.timeTo, errors)
    this.validateRequired('location', this.data.location, errors)
    return errors
  }

  validateRequired(field, val, errors) {
    if(val) return
    errors[field] = 'Field required'
  }

  validateRequiredCity(field, val, errors) {
    if (val || val === null) return
    errors[field] = 'Field required'
  }

  validateCourse(errors) {
    this.validateRequired('courseDate', this.data.course.ID, errors)
    this.validateRequiredCity('courseLocation', this.data.course.city, errors)
  }

  validateDate(errors) {
    if(!(DATE_DEFAULT_REGXP.test(this.data.examDate)))
      errors.examDate = 'Date should be in DD-MM-YYYY format'
  }
}
