import {anyDateFormat, anyDateFormatWithoutYear } from '@/util/const-reg'

export default class SpreadsheetConverter {
  constructor(str) {
    this.str = str
    this.data = {}
    this.firstDateIndex = null
    this.splitedStrWithoutName = []
    this.requests = [
      {
        examDate: null,
        location: null,
        examTime: null
      }
    ]
  }

  getData(str) {
    this.str = str
    this.findFirstDateIndexAndSetBirthday()
    this.splitStr()

    this.setName()
    this.setCandidateNumber()
    this.setEmail()
    this.setTel()
    this.setAccount()
    this.setExamTime()
    this.setExamDate()
    return {...this.data, requests: this.requests}
  }

  findFirstDateIndexAndSetBirthday() {
    let regx = /[0-9]{1,2}[-.\/][0-9]{1,2}[-.\/]\d{4}/
    let res = this.str.match(regx)
    if(!res) return false
    this.findFirstDateIndex = res.index
    this.data.candidateBirthday = res[0]
  }

  setName() {
    this.data.candidateName = null
    if(!this.findFirstDateIndex) return false
    this.data.candidateName = this.str.substring(0, this.findFirstDateIndex).trimRight()
  }

  splitStr() {
    let strWithoutName = this.str.substring(this.findFirstDateIndex)
    this.splitedStrWithoutName = strWithoutName.replace(/(	+)/g, ',').split(',')
  }

  setCandidateNumber() {
    this.data.candidateNumber = null
    let candidateNumber = this.splitedStrWithoutName[1]
    if(candidateNumber.match(/\d{8,10}/)) this.data.candidateNumber = candidateNumber
    else if(candidateNumber.includes('_')) this._setNameLastName(candidateNumber)
  }

  _setNameLastName(str) {
    let [firstName, lastName] = str.split('_')
    this.data.firstName = firstName
    this.data.lastName = lastName
  }

  setEmail() {
    this.data.candidateEmail = null
    let email  = this.splitedStrWithoutName[2]
    if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) 
      this.data.candidateEmail = email
  }

  setTel() {
    this.data.candidateTel = null
    let tel  = this.splitedStrWithoutName[3]
    if(/^\d+$/.test(tel)) 
      this.data.candidateTel = tel
  }


  setAccount() {
    this.data.accountUsername = null
    let account  = this.splitedStrWithoutName[5]
    this.data.accountUsername = account
  }

  setExamTime() {
    let examTime  = this.splitedStrWithoutName[4]
    this.requests[0].timeFrom = examTime
  }

  setExamDate() {
    let date = this.splitedStrWithoutName[this.splitedStrWithoutName.length - 1]
    if(anyDateFormat.test(date)) this.requests[0].examDate = date
  }
}