import CandidateRequestsValidator from "./CandidateRequestsValidator"
import { DATE_INDIAN as DATE_VALIDATION_RULES } from "@/util/validation-rules"

export default class CandidateValidaror {
  constructor(data, validator) {
    this.data = data
    this.validator = validator
  }

  validate() {
    this.validator.resetFields()
    this.validateCandidateNumber()
    this.validateCourse()
    this.validateBirthday()
    this.validatePhone()
    this.validateReservation()

    return this.validator.isValid()
  }

  validateCandidateNumber() {
    if (!this.data.candidateNumber && (!this.data.firstName || !this.data.lastName)) {
      this.validator.invalidField('candidateNumber', 'Candidate should have number or first and last name')
      this.validator.invalidField('firstName', 'Candidate should have number or first and last name')
      this.validator.invalidField('lastName', 'Candidate should have number or first and last name')
    }
    if (this.data.candidateNumber) {
      if (this.data.candidateNumber.length >= 5 && this.data.candidateNumber.length <= 10) return
      else {
        this.validator.invalidField('candidateNumber', 'Candidate number must contain between 5 and 10 character')
      }
    }
  }

  validateCourse() {
    if (!this.data.course.city) return
    if (!this.data.course.ID) this.validator.invalidField('courseDate', 'Please, select course')
  }

  validateBirthday() {
    let validation = DATE_VALIDATION_RULES(this.data.candidateBirthday)
    if (typeof validation === 'boolean') return
    this.validator.invalidField('candidateBirthday', validation)
  }

  validatePhone() {
    if (!this.data.candidateTel) return
    if (this.data.candidateTel.length < 8 || this.data.candidateTel.length > 13)
      this.validator.invalidField('candidateTel', 'Candidatetel should be between 8 and 13')
  }

  validateReservation() {
    if (!this.data.requests.length) return
    if (this.data.filteredRequests.length === 1) {
      if (this.data.filteredRequests[0].exam.ID > -1 || this.data.filteredRequests[0].course.ID > -1 || this.data.filteredRequests[0].course.city) {
        new CandidateRequestsValidator(this.data.filteredRequests, this.validator, 'requests').validate()
      }
    }
    if (this.data.filteredRequests.length > 1) {
      new CandidateRequestsValidator(this.data.filteredRequests, this.validator, 'requests').validate()
    }
  }
}
