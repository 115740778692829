<template lang="pug">
div
  portal(to="candidate-create")
    .searching-actions(v-if="$route.query.search" data-test="candidate-create")
      .searching-actions__wrapper.d-flex.align-center.mr-10
        v-text-field(
          data-test="candidate-converter"
          v-model="candidateFieldStr"
          @input="validateAndShowUserCreating"
          label="Not found candidate? Enter candidate data or click on btn to create new ..."
        ).mr-4.ml-4
        v-btn(fab color="primary" small @click="show" data-test="candidate-create-btn").mt-1.mr-4
            v-icon $vuetify.icons.plus

  add-modal(
    ref="form"
    :value="showing"
    @input="cancel"
    :formErrors="formErrors"
    :itemModel="model"
    @okBtnClicked="createCandidate"
    @failBtnClicked="cancel"
    @clearModel="clear"
    max-width='700px'
  )

    h3.pb-3 Exam reservation
    reservations(
      :model="model" ref="reservations"
      :formErrors="formErrors"
      :avaliableSlots="false"
      :getExamLocation="getExamLocations"
      :getCourseLocation="getCourseLocation"
    )
</template>

<script>
import {mapActions, mapGetters} from "vuex"

import Model from "@/app/admin/modules/candidates/core/candidates-reservation-model"
import SpreadsheetConverter from "@/util/Spreadsheet-converter"
import FormErrors from '@/util/form-errors'
import errorsMixin from '@/mixins/errors.mixin'
import { API_ATTRIBUTES, API_ATTRIBUTES_NESTED } from '../../core/candidates-const'
import CandidateValidator from "../../core/models/validation/candidateValidator"


export default {
  mixins: [errorsMixin],

  data: () => ({
    model: new Model(),
    candidateFieldStr: null,
    showing: false,
    formErrors: new FormErrors()
  }),

  inject: ['svc'],

  methods: {
    ...mapActions({
      getExamLocations: 'crmExamLocation/list',
      getCourseLocation: 'crmCourseCities/list'
    }),
    validateAndShowUserCreating(str) {
      this.candidateFieldStr = str
      let model = new Model()
      model.initBy(str, new SpreadsheetConverter())
      if(!model.isValid()) return false

      this.model = model
      this.show()
    },

    show() {
      this.showing = true
    },

    hide() {
      this.showing = false
    },

    async createCandidate() {
      try {
        if (!this.$refs.form.validate() || !new CandidateValidator(this.model, this.formErrors).validate() ) {
          this.$notify({text: 'invalid fields', type: 'error'})
          return false;
        }
        this.updateRequests()
        await this.svc().create(this.model)
        this.$notify({text: 'User created', type: 'success'})
        this.cancel()
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: API_ATTRIBUTES,
          nestedErrors: API_ATTRIBUTES_NESTED })
      }
    },

    updateRequests() {
      this.model.requests = this.$refs.reservations.requests
    },

    cancel() {
      this.model = new Model()
      this.candidateFieldStr = null
      this.hide()
    },

    clear() {
      this.model = new Model()
      this.formErrors.resetFields()
      this.$refs.reservations.resetRequests()
    },
  },

  components: {
    addModal: () => import('../CandidatesModalAdd.vue'),
    reservations: () => import('../item/CanditeReservations.vue'),
  }
}
</script>

<style lang="scss" scoped>
.searching-actions {
  width: 100%;
  position: fixed;
  background: $default-bg-color;
  bottom: 0px;
  &__wrapper {
    border-top: 1px solid $primary-color;
    background: #fff;
    padding: 15px 0;
    padding-right: 13px;

  }
}
</style>
