import RequestValidate from "./candidatesReservationRequestValidate"

export default class CandidateRequestsValidator {
  constructor(data, validator, field) {
    this.data = data
    this.validator = validator
    this.field = field
  }
  
  validate () {
    this.validator.resetFields()
    if(!this.data.length) return
    this.data.forEach((request, index) => {
      let errors = new RequestValidate(request).validate()
      let fullErrors = []
      if(this.validator.fields[this.field]) fullErrors = this.validator.fields[this.field]
      fullErrors[index] = errors
      if(Object.keys(errors).length) this.validator.invalidField('requests', fullErrors)
    })
    return this.validator.isValid()
  }
}
  